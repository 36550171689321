<template>
  <f7-sheet class="share-sheet" :class="'share_' + shareKey" swipe-to-close backdrop>
    <f7-page-content>
      <div class="share-container">
        <div style="display: flex;justify-content: space-between;">
          <div class="title">
            <h1>{{ $t.getTranslation('LBL_SHARE') }}</h1>
          </div>
          <f7-link style="margin-top:17px;" @click="closePopUp">
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </div>

        <div style="display: flex;align-items: flex-start;">
          <img v-if="!$h.isMobile() && shareLink" :src="$h.getQRCode(shareLink)" style="width:100px;" loading="lazy" />
          <div class="share-link" @click="copyLink" style="flex:1;margin-top:0px;">
            <p style="max-width: 80%;">{{ shareHost ? shareLink.replace(shareHost, '/') : shareLink }}</p>
            <span class="copy">{{ $t.getTranslation('LBL_COPY') }}</span>
          </div>
        </div>
      </div>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { defineComponent, ref, inject, onMounted } from 'vue'
import { f7 } from 'framework7-vue'
import { post } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { useStore } from '@/store'

export default defineComponent({
  name: 'ShareComponent',
  components: {},
  props: { shareData: Object, lazy: { type: Boolean, default: false } },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore();
   
    const linkCode = ref('')
    const shareLink = ref('')
    const shareHost = ref('')
    let shareKey = ref(`${new Date().getTime()}-${helpers.randomCharacters()}`)

    const isSecured = location.protocol.indexOf('https') > -1 ? true : false

    const generateShareLink = async () => {
      if (props.shareData?.type == 'GENERIC') {
        shareLink.value = props.shareData.shareLink
        return
      }

      let generatedShareLink = await post('/share/generate/link', {
        type: props.shareData.type,
        key: props.shareData.key
      })

      if (generatedShareLink) {
        shareHost.value = generatedShareLink?.ShareHost
        linkCode.value = generatedShareLink?.LinkCode
        shareLink.value = generatedShareLink?.ShareLink
      }
    }

    onMounted(() => {
      if (!props.lazy) {
        generateShareLink()
      }
    })

    const showShareSheet = async (isLoad, isQr) => {
      if (isLoad) {
        await generateShareLink()
      }

      if (isQr) {
        window.location.href = `${shareLink.value}?IsQr=1`
        return
      }

      if (navigator.share && isSecured && helpers.isMobile()) {
        //native share dialog
        let sharePayload = {
          files: [],
          title: props.shareData.title,
          text: props.shareData.description,
          url: shareLink.value
        }
        if (props?.shareData?.video) {
          sharePayload.files.push(props.shareData.video)
        }
        navigator.share(sharePayload)
      } else {
        f7.sheet.get('.share_' + shareKey.value).open()
      }
    }

    const closePopUp = () => {
      f7.sheet.close('.share_' + shareKey.value)
    }

    const copyLink = async () => {
      helpers.copyToClipBoard(shareLink.value)

      helpers.createNotification({
        type: 'info',
        title: $t.getTranslation('LBL_INFO'),
        message: $t.getTranslation('LBL_INFO_SUCCESSFULLY_COPY_SHARE_LINK')
      })
    }

    const onShareLink = async (network) => {
      if (linkCode.value) {
        store.dispatch('share/increase', { network, linkCode: linkCode.value })
      }
    }

    return {
      shareKey,
      closePopUp,
      showShareSheet,
      linkCode,
      shareLink,
      shareHost,
      copyLink,
      onShareLink
    }
  }
})
</script>
